import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';
  .ChallengePageWrapper {
    width: 100%;
    background: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 20px;
  }

  .ChallengePage__ChallengeConditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ChallengeAccountsItemWrapper {
    margin-bottom: 2px;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.06);
  }

  .ChallengePageChart__Under {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .ChallengePageChart__Item {
    padding: 12px 24px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    span {
      color: rgba(0, 0, 0, 0.75);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .ChallengePageChart__Value {
    color: rgba(0, 0, 0, 0.75);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    > div {
      background: gray;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
  .ChallengePageWrapper table tr td {
    height: 44px;
    text-align: center;
    border: 1px solid #eeeeee !important;
    border-collapse: collapse !important;
  }

  .ChallengePageWrapper .ChallengePage__Table tr:first-child {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .ChallengePageWrapper .ChallengePage__Table tr:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .ChallengePageWrapper .ChallengePage__Table tr td {
    height: 44px;
    text-align: center;
    border: 1px solid #eeeeee !important;
    border-collapse: collapse !important;
  }

  .ChallengePageWrapper .ChallengePage__Table th {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .ChallengePageWrapper .ChallengePage__Table {
    border: 1px solid black;
    border-collapse: collapse;
  }
  .ChallengePageWrapper .ChallengePage__Table td {
    border: 1px solid black;
    border-collapse: collapse;
  }
`;

export { staticStyles };
