import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import { ModalHOC } from 'common';
import { ReloadIcon, CrossIcon } from 'common/icons';
import { LoginCell, ChallengeInfoElem, MoneyCell, ActionsButton, Tooltip, DefaultButton } from 'common/components';

import { archiveChallenge, restartChallenge } from 'client/challenges/_redux';
import { getAccountTradeLink } from 'client/accounts/_redux';
import { staticStyles } from './style';

import ChallengeProgress from './ChallengeProgress';

const ChallengeItem = ({ activeChallenge, archiveChallenge, getAccountTradeLink, restartChallenge, showModal }) => (
  <div className="ChallengeAccountsItemWrapper">
    <button
      type="button"
      className="ChallengeAccountsItem"
      onClick={() => window.open(`/challenges/${activeChallenge.challenge.id}`)}>
      <LoginCell title={activeChallenge.challenge.title} login={activeChallenge.account.login} />
    </button>
    <div className="ChallengeAccountsItem ChallengeAccountsItem--Right">
      <div className="ChallengeAccountsItem__Metrics">
        <div className="ChallengeAccountsItem__Metrics-Item">
          <FM id="justEquity">{text => <span>{text}:</span>}</FM>{' '}
          <MoneyCell style={{ fontWeight: 500 }} heightMaxContent value={activeChallenge.equity} />
        </div>
        <div className="ChallengeAccountsItem__Metrics-Item">
          <FM id="justTotalProfit">{text => <span>{text}:</span>}</FM>
          <MoneyCell
            style={{ fontWeight: 500 }}
            success={+activeChallenge.totalProfit.amount >= 0}
            danger={+activeChallenge.totalProfit.amount < 0}
            heightMaxContent
            value={activeChallenge.totalProfit}
          />
        </div>
        <div className="ChallengeAccountsItem__Metrics-Item">
          <FM id="justDailyProfit">{text => <span>{text}:</span>}</FM>
          <MoneyCell
            style={{ fontWeight: 500 }}
            success={+activeChallenge.dailyProfit.amount >= 0}
            danger={+activeChallenge.dailyProfit.amount < 0}
            heightMaxContent
            value={activeChallenge.dailyProfit}
          />
        </div>
      </div>
      <ActionsButton
        tooltipId="Restart__tooltip"
        tooltipTextId="justRestartChallenge"
        deleteType
        onClickFunc={() => {
          showModal({
            caption: { id: 'justRestartChallenge' },
            content: { id: 'justAreYouSureYouWantToRestartThisChallenge' },
            actionButton: {
              textId: 'justRestartChallenge',
              handler: () => restartChallenge(activeChallenge.challenge.id),
            },
            cancelButton: null,
          });
        }}>
        <div className="ReloadIcon">
          <ReloadIcon />
        </div>
      </ActionsButton>
      <ActionsButton
        tooltipId="Delete__tooltip"
        tooltipTextId="justArchiveChallenge"
        deleteType
        onClickFunc={() => {
          showModal({
            caption: { id: 'justArchiveChallenge' },
            content: { id: 'justAreYouSureYouWantToArchiveThisChallenge' },
            actionButton: {
              textId: 'justArchive',
              type: 'error',
              handler: () => archiveChallenge(activeChallenge.challenge.id),
            },
            cancelButton: null,
          });
        }}>
        <div className="CrossIconDiv">
          <CrossIcon />
        </div>
      </ActionsButton>
    </div>
    <div className="ChallengeAccountsItem">
      <ChallengeInfoElem>{activeChallenge.title}</ChallengeInfoElem>
      <ChallengeInfoElem innerDotColor="blue">{activeChallenge.level.name}</ChallengeInfoElem>
      <ChallengeInfoElem borderColor="#0AA1E2">{activeChallenge.challenge?.initialAccountBalance}</ChallengeInfoElem>
    </div>
    <div className="ChallengeAccountsItem ChallengeAccountsItem--Right">
      <ChallengeProgress activeChallenge={activeChallenge} />
    </div>
    <div className="ChallengeAccountsItem ChallengeAccountsItem--EmptyCell" />
    <div className="ChallengeAccountsItem ChallengeAccountsItem--WebTerminalButton">
      {activeChallenge.account?.webTerminal && (
        <Tooltip id="clientTradeTooltip" textId={activeChallenge.account?.isBlocked ? 'clientTradeBlockedAccount' : ''}>
          <DefaultButton
            textId="clientTrade"
            filled
            onClick={() => getAccountTradeLink({ accountId: activeChallenge.account.id })}
          />
        </Tooltip>
      )}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default compose(
  ModalHOC,
  connect(null, {
    archiveChallenge: id => archiveChallenge.request(id),
    restartChallenge: id => restartChallenge.request(id),
    getAccountTradeLink: id => getAccountTradeLink.request(id),
  })
)(ChallengeItem);
