import React, { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageLayout, DefaultButton } from 'common/components';

import { ChallengeItem } from 'client/accounts/_components';
import { ModalHOC } from 'common';

import AccountPageOnlyTradingTable from 'client/accounts/AccountPageOnlyTradingTable';
import { staticStyles } from './style';
import { getChallenge } from '../../_redux';

const ChallengePage = ({ challenge, challengeIsLoaded, getChallenge, showModal, history }) => {
  const { challengeId } = useParams();
  useEffect(() => {
    getChallenge(challengeId);
  }, []);

  if (!challengeIsLoaded) {
    return null;
  }
  return (
    <PageLayout
      captionSlug={`Challenge ${challenge.challenge?.title}`}
      showReload
      onReloadClick={() => {
        getChallenge(challengeId);
      }}
      reloading={false}>
      <div className="ChallengePageWrapper">
        <div className="ChallengePage__ChallengeConditions">
          <FM id="justChallengeConditions">{txt => <h3>{txt}</h3>}</FM>

          <DefaultButton
            textId="justAboutChallenges"
            onClick={() => {
              showModal({
                caption: { id: 'justAboutChallenges' },
                content: {
                  id: 'justAboutChallengesTogether',
                },
                actionButton: {
                  textId: 'justGoToChallengePage',
                  handler: () => {
                    history.push('/challenges/');
                  },
                },
                cancelButton: {
                  textId: 'justClose',
                },
              });
            }}
          />
        </div>

        <table className="ChallengePage__Table" width="100%">
          <tr>
            <td>
              <FM id="justMinimalTradingDays" />
            </td>
            <td>
              <FM id="justMaximumDailyLoss" />
            </td>
            <td>
              <FM id="justMaximumLoss" />
            </td>
            <td>
              <FM id="justProfitTarget" />
            </td>
          </tr>
          <tr>
            <td>{challenge.level.minimum_trading_days}</td>
            <td>{`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}</td>
            <td>{`${challenge.level.maximum_total_loss.amount} ${challenge.level.maximum_total_loss.currency}`}</td>
            <td>{`${challenge.level.target.amount} ${challenge.level.target.currency}`}</td>
          </tr>
        </table>

        {challengeIsLoaded && <ChallengeItem activeChallenge={challenge} />}

        <div className="ChallengePageChart" />

        <div className="ChallengePageChart__Under">
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumDailyLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}
              <div style={{ background: '#3BAA9A' }} />
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}
              <div style={{ background: '#3BAA9A' }} />
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justProfit" />
            <div className="ChallengePageChart__Value">
              {`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}
              <div style={{ background: '#FF4058' }} />
            </div>
          </div>
        </div>
      </div>
      <AccountPageOnlyTradingTable />
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

export default compose(
  withRouter,
  ModalHOC,
  connect(
    state => ({
      challenge: state.client.challenges.challenge,
      challengeIsLoaded: state.client.challenges.challengeIsLoaded,
    }),
    {
      getChallenge: id => getChallenge.request(id),
    }
  )
)(ChallengePage);
